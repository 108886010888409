<script setup lang="ts">
import dayjs from "dayjs";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { TgButton, OutlineButton } from "@/components/common";
import { AutocompleteUsers, AutocompleteScac } from "@/components/autocomplete";
import TicketPanel from "@/components/customerSupport/TicketPanel.vue";
import PriorityDropdown from "@/components/customerSupport/PriorityDropdown.vue";
import TagsDropdown from "@/components/customerSupport/TagsDropdown.vue";
import AssetFieldsRepeater from "@/components/customerSupport/AssetFieldsRepeater.vue";
import { useBapi } from "@/bapi-client";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import useNotificationStore from "@/stores/notifications";
import { CustomerSupportTicket } from "@/bapi-client/types/customer-support";

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();

interface Props {
  customerId: string;
  ticketId?: string;
}

interface TicketForm {
  name: string;
  priority: string;
  loaded_empty: "L" | "E";
  railroads: string;
  assignees: string;
  equipment: {
    equipment_id: string;
    waybill_id: string;
    reporting_groups: string;
  }[];
  tags: string[];
  description?: string;
}

const props = defineProps<Props>();

const labelClasses = "text-sm w-16 min-w-16 !text-gray-500 font-semibold !mb-0 self-center";

const userName = computed(() => {
  return route.name === "editTicket"
    ? ticket.value?.createdBy?.fullName
    : userStore.user?.firstName + " " + userStore.user?.lastName;
});

async function submit(form: TicketForm) {
  const waybill_ids: string[] = [];
  const asset_ids: string[] = [];
  const reporting_groups: string[] = [];
  for (const equip of form.equipment) {
    if (equip.waybill_id) {
      waybill_ids.push(equip.waybill_id);
    }
    if (equip.equipment_id !== undefined) {
      asset_ids.push(equip.equipment_id);
    }
    if (equip.reporting_groups?.length) {
      if (Array.isArray(equip.reporting_groups)) {
        for (const group of equip.reporting_groups) {
          const [id] = Object.keys(group);
          reporting_groups.push(id);
        }
      } else {
        for (const group of equip.reporting_groups.split(",")) {
          reporting_groups.push(group);
        }
      }
    }
  }

  const formData = {
    customer_id: props.customerId,
    ticket_id: props.ticketId ?? "",
    name: form.name,
    waybill_ids,
    asset_ids,
    reporting_groups,
    followers: [],
    assignees: form.assignees !== undefined ? [form.assignees] : [],
    tags: form.tags,
    priority: form.priority,
    railroads: form.railroads !== undefined ? [form.railroads] : [],
    loaded_empty: form.loaded_empty,
    description: form.description ?? "",
    state: form.assignees?.length ? "In Progress" : "Unassigned",
  };

  if (route.name === "editTicket") {
    const result = await useBapi(BAPI_COMMANDS.CUSTOMER_SUPPORT_UPDATE_TICKET, formData);
    const notifier = useNotificationStore();
    if (!result.success) {
      notifier.setToast("danger", `Unable to update ticket ${props.ticketId}.`);
      return router.push({ name: "customerSupport", params: { customerId: props.customerId } });
    }
    notifier.setToast("success", `Ticket ${props.ticketId} updated!`);
    ticket.value = result.data;
    return router.push({ name: "ticket", params: { customerId: props.customerId, ticketId: props.ticketId } });
  }

  const { ticket_id, ...body } = formData;
  const result = await useBapi(BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET, body);

  if (result.success) {
    const notifier = useNotificationStore();
    notifier.setToast("success", `Ticket ${result.data.id} created!`);
    useBapi(BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKETS, props.customerId);
    return router.push({ name: "ticket", params: { customerId: props.customerId, ticketId: result.data.id } });
  }

  const notifier = useNotificationStore();
  notifier.setToast("danger", "Unable to create ticket.");
}

const ticket = ref<CustomerSupportTicket>();
const showEquipment = computed(() => !props.ticketId || ticket.value);
onMounted(async () => {
  if (props.ticketId) {
    const res = await useBapi("getTicket", props.customerId, props.ticketId);
    if (!res.success) {
      const notifier = useNotificationStore();
      notifier.setToast("danger", `Could not load ticket ${props.ticketId}`);
      router.push({ name: "customerSupport", params: { customerId: props.customerId } });
      return;
    }
    ticket.value = res.data;
  }
});

function goBack() {
  if (route.name === "editTicket") {
    return router.push({ name: "ticket", params: { customerId: props.customerId, ticketId: props.ticketId } });
  }
  router.push({ name: "customerSupport", params: { customerId: props.customerId } });
}
</script>

<template>
  <TicketPanel
    :customer-id="customerId"
    :title="route.name === 'editTicket' ? `Edit ticket ${ticket?.id}` : 'New Ticket'"
  >
    <FormKit
      v-if="route.name === 'editTicket' ? ticket : true"
      v-slot="{ state: { valid } }"
      type="form"
      :actions="false"
      :incomplete-message="false"
      :classes="{ form: 'grid grid-cols-2 gap-6' }"
      @submit="submit"
    >
      <FormKit
        type="text"
        name="name"
        validation="required"
        data-testid="ticket-name-input"
        label="Ticket Name"
        :value="ticket?.name"
        :classes="{
          outer: 'border-b py-6 col-span-full',
          label: 'text-sm text-gray-500 font-semibold',
          inner: 'shadow-inner rounded-none',
        }"
      />
      <div class="col-span-1 grid gap-6">
        <PriorityDropdown :value="ticket?.priority" :label-classes="labelClasses" />
        <FormKit
          type="dropdown"
          data-testid="le-input"
          name="loaded_empty"
          label="L/E"
          placeholder="- -"
          :options="[
            { label: 'Loaded', value: 'L' },
            { label: 'Empty', value: 'E' },
          ]"
          :classes="{
            label: labelClasses + ' w-1/4',
            wrapper: 'flex !flex-row gap-1.5',
          }"
          :selected-icon="null"
          :value="ticket?.loadedEmpty"
        />
        <div class="flex items-center gap-1.5">
          <span :class="labelClasses">Railroad</span>
          <AutocompleteScac
            name="railroads"
            :value="ticket?.railroads ? ticket.railroads[0] : undefined"
            :customer-id="customerId"
            label=""
          />
        </div>
      </div>
      <div class="col-span-1 grid auto-rows-max gap-10 self-end">
        <div class="flex items-center text-sm">
          <span :class="labelClasses" class="w-1/4">Creator</span>
          {{ userName }}
          <FormKit type="hidden" name="creator" data-testid="creator-input" :value="userName" />
        </div>
        <div class="flex items-center">
          <p :class="labelClasses" class="w-1/4">Date</p>
          <p v-if="ticket" class="text-sm">{{ $date("MM/DD/YYYY", ticket.createdTs) }}</p>
          <p v-else class="text-sm">{{ dayjs().format("MM/DD/YYYY") }}</p>
          <FormKit
            v-if="ticket"
            type="hidden"
            data-testid="date-input"
            name="date"
            :value="dayjs(ticket.createdTs).format('MM/DD/YYYY')"
          />
          <FormKit v-else type="hidden" data-testid="date-input" name="date" :value="dayjs().format('MM/DD/YYYY')" />
        </div>
        <div class="flex items-center gap-1.5">
          <span :class="labelClasses">Assignee</span>
          <AutocompleteUsers
            name="assignees"
            :customer-id="customerId"
            :value="ticket?.assignees[0]?.userId"
            :user-name="ticket?.assignees[0]?.fullName"
            :required="ticket?.assignees !== undefined && ticket.assignees.length > 0"
            flat
          />
        </div>
      </div>

      <div class="col-span-full">
        <TagsDropdown :label-classes="labelClasses" :value="ticket?.tags" />
      </div>

      <div class="col-span-full border-y pt-3">
        <AssetFieldsRepeater
          v-if="showEquipment"
          :ticket="ticket"
          :customer-id="customerId"
          :label-classes="labelClasses"
        />
      </div>
      <div class="col-span-full border-b">
        <FormKit
          type="textarea"
          data-testid="ticket-description-input"
          name="description"
          label="Ticket Description"
          :classes="{
            label: 'text-sm text-gray-500 font-semibold',
            inner: 'shadow-inner',
            outer: 'pb-6',
          }"
          :value="ticket?.description"
        />
      </div>
      <div class="col-span-full flex items-center justify-between">
        <OutlineButton data-testid="ticket-cancel-button" color="danger" is-small @click.prevent="goBack">
          Cancel
        </OutlineButton>
        <TgButton data-testid="ticket-create-button" type="submit" :disabled="!valid" is-small>
          {{ route.name === "editTicket" ? "Save" : "Create" }}
        </TgButton>
      </div>
    </FormKit>
  </TicketPanel>
</template>
