import { SchemaNode } from "@telegraphio/tedi-view-builder/node";
import edi404TenderObj from "@telegraphio/tedi-view-builder/examples/edi.8030.404.everything.generic.json";
import ediOverrides from "@/edi.overrides.json";
import { SchemaBuilder, SchemaRepository } from "@telegraphio/tedi-view-builder/railmerge";
import { designList } from "@/types/ediBuilder";
import { formatEDILabel } from "@/utils/edi";
import {
  FormKitNode,
  EquipmentAdapter,
  EquipmentReferenceAdapter,
  PartyIdentificationAdapter,
  LocationLookupAdapter,
  LocationFieldAdapter,
  CommoditiesAdapter,
  LineItemAndWeightAdapter,
  CommodityLookupAdapter,
  CarrierLookupAdapter,
  PriceAuthorityIdentificationAdapter,
  EmptyCarDispositionAdapter,
  ElementFlexRowAdapter,
  DropdownAdapter,
  DateAdapter,
  TimeAdapter,
  CheckboxAdapter,
  TemperatureUnitAdapter,
  BaseElementAdapter,
  BaseArrayAdapter,
  BaseObjectAdapter,
  ensureGrouping,
} from "@/formkit.adapter";
import type { PatternInputDetails } from "@telegraphio/papi-client";

const adapterList = [
  CarrierLookupAdapter,
  LocationLookupAdapter,
  LocationFieldAdapter,
  DropdownAdapter,
  DateAdapter,
  TimeAdapter,
  CheckboxAdapter,
  TemperatureUnitAdapter,
  EquipmentAdapter,
  EquipmentReferenceAdapter,
  PartyIdentificationAdapter,
  CommoditiesAdapter,
  LineItemAndWeightAdapter,
  CommodityLookupAdapter,
  PriceAuthorityIdentificationAdapter,
  EmptyCarDispositionAdapter,
  ElementFlexRowAdapter,
  BaseElementAdapter,
  BaseArrayAdapter,
  BaseObjectAdapter,
];

const exclusionList = [
  "beginning_segment_for_data_correction_or_change_ZC1",
  "interchange_control_header_ISA",
  "interchange_control_trailer_IEA",
  "functional_group_header_GS",
  "functional_group_trailer_GE",
  "loop_header_LS",
  "loop_trailer_LE",
  "transaction_set_header_ST",
  "transaction_set_trailer_SE",
  "general_shipment_information_BX.transaction_set_purpose_code_01",
  "transaction_set_line_number_LX_loop.transaction_set_line_number_LX.assigned_number_01",
  "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5.commodity_code_qualifier_04",
  "party_identification_N1_loop.party_identification_N1.identification_code_qualifier_03",
];

function formatPath(path: string) {
  return path.replace(/\.\[\d+\]/g, "");
}

export function useEdiBuilder(pattern: string, inputs: Record<string, PatternInputDetails>, isPattern: boolean) {
  const builder = new SchemaBuilder(edi404TenderObj, []);
  const repository = new SchemaRepository(edi404TenderObj, ediOverrides);
  let schema: SchemaNode;

  const prefilledData = pattern ? JSON.parse(pattern) : {};
  const variableInputs = Object.values(inputs);

  const variableList = variableInputs.map((input) => {
    // Strip out index values for input paths that are within repeaters
    return formatPath(input.target || "");
  });

  const finalDesignList = [...new Set([...designList, ...variableList])];

  try {
    if (isPattern) {
      schema = builder.composeSchema(prefilledData, finalDesignList, ediOverrides, exclusionList);

      const designSchema = schema.root();

      const rendered = builder.renderToMap<FormKitNode>(designSchema, adapterList);

      for (const key in rendered) {
        rendered[key] = ensureGrouping(rendered[key], isPattern);
      }

      return { rendered };
    } else {
      const rendered = Object.entries(inputs).map(([key, input]) => {
        const path = input.target;

        if (!path) {
          return;
        }

        const formattedPath = formatPath(path);
        const schema = repository.getSchemaAtPath(formattedPath);

        if (!schema) {
          throw new Error(`No schema found for path: ${path}`);
        }

        const node = builder.render(schema, adapterList);

        node[0].name = key;

        if (!input.optional) {
          node[0].validation = `required|${node[0].validation || ""}`;
        }

        if (input._default) {
          node[0].value = input._default;
        }

        const pathSegements = path.split(".");
        pathSegements.pop();

        const originalLabel = node[0].label;

        let label = "";

        pathSegements.forEach((part, index) => {
          if (part.startsWith("[") && part.endsWith("]")) {
            label += `[${parseInt(part.slice(1, -1)) + 1}] `;
            return;
          }

          label += `${index > 0 ? "-> " : ""} ${formatEDILabel(part)}`;
        });

        if (originalLabel) {
          label += ` -> ${originalLabel}`;
        }

        node[0].label = key === path ? label : key;
        node[0].steplabel = key === path ? originalLabel : key;

        return node;
      });

      return { rendered };
    }
  } catch (error) {
    console.log(error);
    return { rendered: {} };
  }
}
