import dayjs from "dayjs";
import {
  HandlerCustomerSupportTicketResponse,
  BAPITicketAsset,
  TicketAsset,
  TicketNote,
  BAPICustomerSupportTicket,
  BAPICustomerSupportTicketNote,
} from "@/bapi-client/types/customer-support";
import { attachmentFactory } from "../attachments";
import { autocompleteUserFactory } from "../autocomplete";
import { AutocompleteUser, BAPIAutocompleteUser } from "@/bapi-client/types/autocomplete";

export function customerSupportTicketFactory(data: BAPICustomerSupportTicket): HandlerCustomerSupportTicketResponse {
  return {
    id: data.id,
    customerId: data.customer_id,
    idPrefix: data.id_prefix,
    name: data.name,
    state: data.state,
    createdTs: dayjs(data.created_ts).format("MM/DD/YYYY"),
    status: data.status,
    version: data.version,
    updatedTsMax: data.updated_ts_max ?? null,
    updatedTsMin: data.updated_ts_min ?? null,
    createdByUserId: data.created_by_user_id,
    createdBy: autocompleteUserFactory(data.created_by),
    lastEditedByUserId: data.last_edited_by_user_id,
    lastEditedByUser: data.last_edited_by_user ? autocompleteUserFactory(data.last_edited_by_user) : undefined,
    qualifiedId: data.qualified_id,
    closedTsMax: data.closed_ts_max ?? null,
    closedTsMin: data.closed_ts_min ?? null,
    assetIds: data.asset_ids ?? [],
    ticketAssets: data.ticket_assets?.map(ticketAssetFactory) ?? [],
    reportingGroups: data.reporting_groups ?? [],
    priority: data.priority ?? "",
    loadedEmpty: data.loaded_empty ?? "",
    railroads: data.railroads ?? [],
    assignees: data.assignees.map(
      (assignee: BAPIAutocompleteUser): AutocompleteUser => autocompleteUserFactory(assignee),
    ),
    followers: data.followers ?? [],
    tags: data.tags ?? [],
    description: data.description ?? "",
    sortBy: data.sort_by ?? null,
  };
}

export function ticketAssetFactory(ticketAsset: BAPITicketAsset): TicketAsset {
  return {
    waybillId: ticketAsset.waybill_id,
    waybillNumber: ticketAsset.waybill_number,
    waybillDate: dayjs(ticketAsset.waybill_date).format("MM/DD/YYYY"),
    assetId: ticketAsset.equipment_id,
    reportingGroups: ticketAsset.reporting_group_name_map,
  };
}

export function customerSupportTicketNoteFactory(data: BAPICustomerSupportTicketNote): TicketNote {
  return {
    id: data.id,
    createdTs: dayjs.utc(data.created_ts).local().format("MM/DD/YYYY HH:mm:ss"),
    ticketId: data.ticket_id,
    ticketIdPrefix: data.ticket_id_prefix,
    user: autocompleteUserFactory(data.user),
    note: data.note,
    attachments: data.attachments?.map((attachment) => attachmentFactory(attachment)) ?? [],
  };
}
export function customerSupportTicketNotesFactory(data: BAPICustomerSupportTicketNote[]): TicketNote[] {
  return data.map(customerSupportTicketNoteFactory);
}
