import { computed, reactive, toRef, Ref, ref } from "vue";
import { v4 as uuid } from "uuid";
import { bfs, FormKitNode, register } from "@formkit/core";
import type { CIFPartyItem, PatternInputDetails } from "@telegraphio/papi-client";

type StepData = {
  valid: Ref<boolean>;
  dirty: Ref<boolean>;
  blockingCount: number;
  errorCount: number;
  seen: boolean;
};

export default function useFormPlugins(variableInputs: Ref<Record<string, PatternInputDetails>>, isPattern: boolean) {
  const steps = reactive<Record<string, StepData>>({});
  const variableInputsWithIds = ref<Record<string, PatternInputDetails>>({});

  const variablePathsToKeys = computed<Record<string, string>>(() =>
    Object.entries(variableInputs.value).reduce((acc, [key, input]) => {
      return {
        ...acc,
        [input.target as string]: key,
      };
    }, {}),
  );
  const variablePaths = computed(() => Object.keys(variablePathsToKeys.value));

  const buildPath = (node: FormKitNode) => {
    let path = node.name;
    let parent = node.parent;
    let previousName = null;

    while (parent) {
      // Top level segments are wrapped by a group with the same name. So if the name matches
      // the previous name, we know we've reached the top.
      if (parent.name === previousName) {
        break;
      }

      let name = parent.name;

      if (Number.isInteger(parseInt(name))) {
        name = `[${name}]`;
      }

      path = `${name}.${path}`;
      previousName = parent.name;
      parent = parent.parent;
    }

    return path;
  };

  const stepPlugin = (node: FormKitNode) => {
    const key = isPattern ? node.name : node.props.attrs.steplabel;
    const conditional = isPattern ? node.props.type === "group" : node.props.type !== "form";

    if (conditional) {
      // builds an object of the top-level groups
      steps[key] = steps[key] || { seen: false };

      node.on("created", () => {
        // use 'on created' to ensure context object is available
        if (node.context) {
          steps[key].valid = toRef(node.context.state, "valid");
          steps[key].dirty = toRef(node.context.state, "dirty");
        }
      });

      // NEW: Store or update the count of blocking validation messages.
      // FormKit emits the "count:blocking" event (with the count) each
      // time the count changes.
      node.on("count:blocking", ({ payload: count }: { payload: number }) => {
        steps[key].blockingCount = count;
      });

      // NEW: Store or update the count of backend error messages.
      node.on("count:errors", ({ payload: count }: { payload: number }) => {
        steps[key].errorCount = count;
      });

      // Stop plugin inheritance to descendant nodes
      return false;
    }
  };

  const pathPlugin = (node: FormKitNode) => {
    const excludeTypes = ["form", "group", "repeater"];

    if (!excludeTypes.includes(node.props.type)) {
      const path = buildPath(node);

      const nodeId = uuid();

      node.props.id = nodeId;

      const isVariableCheckbox = node.name.endsWith("-checkbox");

      const truePath = isVariableCheckbox ? path.split("-")[0] : path;

      if (variablePaths.value.includes(truePath) && !isVariableCheckbox) {
        node.props.disabled = true;

        const key = variablePathsToKeys.value[truePath];
        const input = variableInputs.value[key];
        variableInputsWithIds.value[key] = { ...input, target: nodeId };
      }

      if (isVariableCheckbox) {
        // We don't want the checkbox values in the output, so we intercept
        // the value and return undefined instead.
        node.hook.input((_, next) => {
          return next(undefined);
        });

        node.on("mounted", () => {
          const el = document.getElementById(nodeId) as HTMLInputElement;
          if (variablePaths.value.includes(truePath)) {
            el.checked = true;
          }
        });
      }

      register(node);

      node.on("destroying", () => {
        const variableKey = Object.entries(variableInputsWithIds.value).find(
          ([, input]) => input.target === nodeId,
        )?.[0];

        if (variableKey) {
          delete variableInputsWithIds.value[variableKey];
        }
      });
    }

    return true;
  };

  const locationPlugin = (node: FormKitNode) => {
    const lookupNodes = ["origin-lookup", "destination-lookup", "disposition-lookup", "party-lookup"];

    if (node.props.type === "autocomplete" && lookupNodes.includes(node.name)) {
      const parent = node.parent as FormKitNode;

      node.on("created", () => {
        let city = null;
        let state = null;
        let country = null;

        if (node.name === "disposition-lookup") {
          city = bfs(parent, "city_name_01", "name")?.value;
          state = bfs(parent, "state_or_province_code_02", "name")?.value;
          country = bfs(parent, "country_code_04", "name")?.value;
        } else {
          city = bfs(parent, "city_name_02", "name")?.value;
          state = bfs(parent, "state_or_province_code_03", "name")?.value;
          country = bfs(parent, "country_code_04", "name")?.value;
        }

        if (!city && !state && !country) {
          return;
        }

        const location = `${city || ""}, ${state || ""}, ${country || ""}`;

        node.input(location);
      });

      node.hook.commit((value, next) => {
        if (!value) {
          return next(value);
        }

        const [city, state, country] = value.split(", ");

        if (node.name === "disposition-lookup") {
          bfs(parent, "city_name_01", "name")?.input(city);
          bfs(parent, "state_or_province_code_02", "name")?.input(state);
          bfs(parent, "country_code_04", "name")?.input(country);
        } else {
          bfs(parent, "city_name_02", "name")?.input(city);
          bfs(parent, "state_or_province_code_03", "name")?.input(state);
          bfs(parent, "country_code_04", "name")?.input(country);
        }

        next(undefined);
      });
    }
  };

  const commodityPlugin = (node: FormKitNode) => {
    if (node.name === "commodity-lookup") {
      node.hook.commit((value, next) => {
        if (!value) {
          return;
        }

        const parent = node.parent as FormKitNode;
        const [code, description] = value.split(" - ");

        bfs(parent, "lading_description_02", "name")?.input(description);
        bfs(parent, "commodity_code_03", "name")?.input(code);

        next(undefined);
      });
    }
  };

  const partyPlugin = (node: FormKitNode) => {
    if (node.name === "party-lookup") {
      node.hook.commit((value: CIFPartyItem, next) => {
        if (!value) {
          return;
        }

        const parent = node.parent as FormKitNode;

        bfs(parent, "name_02", "name")?.input(value.customerName);
        bfs(parent, "identification_code_04", "name")?.input(value.customerId);
        bfs(parent, "address_information_01", "name")?.input(value.address1);
        bfs(parent, "address_information_02", "name")?.input(value.address2);
        bfs(parent, "city_name_01", "name")?.input(value.city);
        bfs(parent, "state_or_province_code_02", "name")?.input(value.state);
        bfs(parent, "postal_code_03", "name")?.input(value.postal);
        bfs(parent, "country_code_04", "name")?.input(value.country);

        next(undefined);
      });
    }
  };

  return {
    steps,
    variableInputsWithIds,
    buildPath,
    stepPlugin,
    pathPlugin,
    locationPlugin,
    commodityPlugin,
    partyPlugin,
  };
}
