<script setup lang="ts">
import { computed, onMounted, ref, unref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { LayoutBox } from "@/components/layout";
import ProgressStep from "./ProgressStep.vue";
import useFormPlugins from "@/composables/waybillFormPlugins";
import { useEdiBuilder } from "@/composables/ediBuilder";
import { usePapiClient } from "@/composables/papiClient";
import { getNode, submitForm } from "@formkit/core";
import useNotificationStore from "@/stores/notifications";
import { useBapi } from "@/bapi-client";
import type { FormKitSchemaNode } from "@formkit/core";
import type { PatternInputDetails } from "@telegraphio/papi-client";

import { TgCard, DialogModal } from "@/components/common";
import TgButton from "@/components/common/TgButton.vue";
import SidebarButtonWithPopover from "./SidebarButtonWithPopover.vue";

const props = defineProps<{
  companyId: string;
  patternId?: string;
}>();

const variableInputsWithPaths = ref<Record<string, PatternInputDetails>>({});

const isPattern = computed(() => route.name === "createPattern" || route.name === "editPattern");

const route = useRoute();
const router = useRouter();
const notifier = useNotificationStore();
const trackers = useTrackerStore();
const {
  steps,
  variableInputsWithIds,
  buildPath,
  stepPlugin,
  pathPlugin,
  locationPlugin,
  commodityPlugin,
  partyPlugin,
} = useFormPlugins(variableInputsWithPaths, isPattern.value);
const { papiApi } = usePapiClient();

const isNew = route.name === "createPattern";

const patternForm = ref();
const documentTitle = ref("");
const schemaMap = ref<Record<string, any>>({});
const isSaving = ref(false);
const showModal = ref(false);
const selectedCheckbox = ref("");
const selectedInput = ref("");
const selectedLabel = ref("carta_porte_uuid");
const isOptional = ref(false);
const inputSchema = ref<FormKitSchemaNode>({ $formkit: "text" });
const defaultValue = ref("");

const labelOptions = [
  { value: "carta_porte_uuid", label: "carta_porte_uuid" },
  { value: "commodity_quantity", label: "commodity_quantity" },
  { value: "commodity_weight", label: "commodity_weight" },
  { value: "equipment_initial", label: "equipment_initial" },
  { value: "equipment_number", label: "equipment_number" },
  { value: "lading_quantity", label: "lading_quantity" },
  { value: "line_item_weight", label: "line_item_weight" },
  { value: "m3_date", label: "m3_date" },
  { value: "m3_release_code", label: "m3_release_code" },
  { value: "m3_time", label: "m3_time" },
  { value: "n9_bol_date", label: "n9_bol_date" },
  { value: "n9_bol_number", label: "n9_bol_number" },
  { value: "n9_bol_time", label: "n9_bol_time" },
  { value: "n9_cn_number", label: "n9_cn_number" },
  { value: "net_weight", label: "net_weight" },
  { value: "shipment_number", label: "shipment_number" },
];

const segmentOrder = [
  "general_shipment_information_BX",
  "rail_shipment_information_BNX",
  "release_M3",
  "extended_reference_information_N9",
  "equipment_details_N7_loop",
  "transaction_set_line_number_LX_loop",
  "party_identification_N1_loop",
  "origin_station_F9",
  "destination_station_D9",
  "route_information_R2",
  "protective_service_instructions_PS",
  "empty_car_disposition_pended_destination_consignee_E1_loop",
];

const sortedSchema = computed(() => {
  return Object.entries(schemaMap.value).sort(([a], [b]) => {
    const aIndex = segmentOrder.indexOf(a);
    const bIndex = segmentOrder.indexOf(b);

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });
});

const firstStep = computed(() => Object.keys(steps)[0]);
const isPatternFormValid = computed(() => patternForm?.value?.node.context.state.valid);

const schemaData = ref({
  variableCheckboxAttributes: {
    onChange: (e: any) => {
      const id = e.target.id;

      const checkboxNode = getNode(id);

      if (!checkboxNode) {
        console.log("No checkbox node found for id:", id);
        return;
      }

      // Strip off '-checkbox' from the end of the name
      const fieldName = checkboxNode.name.slice(0, checkboxNode.name.length - 9);
      const node = checkboxNode.parent?.children.find((child) => child.name === fieldName);

      if (!node) {
        console.log("No input node found with name:", fieldName);
        return;
      }

      if (e.target.checked) {
        node.input(undefined);
        node.props.disabled = true;
        selectedCheckbox.value = id;
        selectedInput.value = node.props.id;

        const schema: FormKitSchemaNode = {
          $formkit: node.props.type,
          label: "Default value",
          name: "default-value",
          classes: {
            outer: "mb-6",
          },
        };

        if (node.props.validation) {
          schema.validation = node.props.validation;
        }

        if (node.props.type === "number") {
          schema.max = node.props.attrs.max;
          schema.min = node.props.attrs.min;
        }

        if (node.props.type === "dropdown") {
          schema.$formkit = "select";
          schema.options = [{ label: "None", value: "" }, ...node.props.options];
        }

        inputSchema.value = schema;

        showModal.value = true;
      } else {
        node.props.disabled = false;
        const [key] =
          Object.entries(variableInputsWithIds.value).find(([, value]) => value.target === node.props.id) ?? [];

        if (key) {
          delete variableInputsWithIds.value[key];
        }
      }
    },
  },
  searchSttcs: async ({ search }: { search: string }) => {
    const result = await useBapi("getStccs", {
      searchTerm: search,
      context: "pricing",
    });

    if (!result.success) {
      return [];
    }

    const seenSTCCs = new Set<string>();

    return result.data.reduce(
      (acc, stcc) => {
        if (seenSTCCs.has(stcc.associatedSTCC)) {
          return acc;
        }

        seenSTCCs.add(stcc.associatedSTCC);

        const value = `${stcc.associatedSTCC} - ${stcc.commodity}`;

        return [...acc, { label: value, value }];
      },
      [] as { label: string; value: string }[],
    );
  },
  searchLocations: async ({ search }: { search: string }) => {
    const result = await useBapi("getAllLocations", {
      search,
    });

    if (!result.success) {
      return [];
    }

    return result.data.map((location) => {
      const formattedLocation = `${location.city}, ${location.state}, ${location.country}`;

      return {
        label: formattedLocation,
        value: formattedLocation,
      };
    });
  },
  searchScacs: async ({ search }: { search: string }) => {
    const result = await useBapi("autocompleteScacs", props.companyId, search);

    if (!result.success) {
      return [];
    }

    return result.data;
  },
  searchCifs: async ({ search }: { search: string }) => {
    try {
      const result = await papiApi.bookingGetCIFByFuzzyQuery({
        getCIFFuzzyQueryRequest: { term: search },
      });

      const { results = [] } = result;

      return results.map((customer) => {
        const { address1, address2, city, country, customerName, postal, state } = customer;

        return {
          label: `${customerName} - ${address1}, ${address2 ? address2 + ", " : ""}${city}, ${state}, ${postal}, ${country}`,
          value: customer,
        };
      });
    } catch (error) {
      return [];
    }
  },
});

function handleTrackCancel() {
  const rsEvent = isPattern.value
    ? isNew
      ? RUDDERSTACK_EVENTS.WAYBILLING_NEW_PATTERN_CANCEL
      : RUDDERSTACK_EVENTS.WAYBILLING_EDIT_PATTERN_CANCEL
    : RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_CANCEL;

  trackers.logRudderstackEvent(rsEvent, { pattern_id: props.patternId });
}

async function handleSavePattern(data: any) {
  const rsEvent = isNew
    ? RUDDERSTACK_EVENTS.WAYBILLING_NEW_PATTERN_CREATE
    : RUDDERSTACK_EVENTS.WAYBILLING_EDIT_PATTERN_SAVE;

  try {
    isSaving.value = true;

    const variables = Object.entries(variableInputsWithIds.value).reduce((acc, [key, value]) => {
      const node = getNode(value.target || "");

      if (!node) {
        return {
          ...acc,
          [key]: value,
        };
      }

      const path = buildPath(node);

      return {
        ...acc,
        [key]: {
          ...value,
          target: path,
        },
      };
    }, {});

    // The FormKit output contains nested duplicate keys, so we need to flatten it
    const pattern = Object.keys(data).reduce((acc, key) => {
      return { ...acc, [key]: data[key][key] };
    }, {});

    const response = await papiApi.bookingUpsertPattern({
      customerId: props.companyId,
      upsertPatternRequest: {
        patternId: props.patternId,
        pattern: JSON.stringify(pattern),
        label: documentTitle.value,
        inputs: variables,
      },
    });

    trackers.logRudderstackEvent(rsEvent, {
      success: true,
      pattern_id: isNew ? response.patternId : props.patternId,
      pattern_name: documentTitle.value,
    });

    notifier.setToast("success", "Pattern saved successfully");
    router.push({ name: "patternsList" });
  } catch (error) {
    trackers.logRudderstackEvent(rsEvent, {
      success: false,
      pattern_id: props.patternId,
    });

    notifier.setToast("danger", "Failed to save pattern");
  } finally {
    isSaving.value = false;
  }
}

async function handleSubmitTender(data: Record<string, string>) {
  try {
    isSaving.value = true;

    const response = await papiApi.bookingCreateTenderFromPattern({
      customerId: props.companyId,
      createTenderPatternRequest: {
        inputs: data,
        patternId: props.patternId,
      },
    });

    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_SUBMIT, {
      success: true,
      pattern_id: props.patternId,
      tender_request_id: response.requestId,
    });

    notifier.setToast("success", "Tender sumbitted successfully");
    router.push({ name: "waybillsList" });
  } catch (error) {
    trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_CREATE_TENDER_SUBMIT, {
      success: true,
      pattern_id: props.patternId,
    });

    notifier.setToast("danger", "Failed to submit tender");
  } finally {
    isSaving.value = false;
  }
}

function closeModal() {
  selectedLabel.value = "carta_porte_uuid";
  selectedCheckbox.value = "";
  selectedInput.value = "";
  isOptional.value = false;
  defaultValue.value = "";
  showModal.value = false;
}

function handleSaveVariableOptions() {
  if (!selectedInput.value) {
    return;
  }

  variableInputsWithIds.value[selectedLabel.value] = {
    target: selectedInput.value,
    optional: isOptional.value,
    _default: defaultValue.value,
  };

  closeModal();
}

function handleModalDismiss() {
  if (!selectedInput.value) {
    return;
  }

  const inputNode = getNode(selectedInput.value);

  if (inputNode) {
    inputNode.props.disabled = false;
  }

  const el = document.getElementById(selectedCheckbox.value) as HTMLInputElement;
  el.checked = false;

  closeModal();
}

onMounted(async () => {
  let patternString = "";

  if (!isNew) {
    const response = await papiApi.bookingGetPattern({
      customerId: props.companyId,
      getPatternRequest: { patternId: props.patternId },
    });

    const { label, pattern, inputs } = response;
    const initialInputs = inputs ?? {};

    documentTitle.value = label ?? "";
    patternString = pattern ?? "";
    variableInputsWithPaths.value = initialInputs;
  }

  const { rendered } = useEdiBuilder(patternString, variableInputsWithPaths.value, isPattern.value);
  schemaMap.value = rendered;

  const groups = document.querySelectorAll(".waybill-form-group");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const { id } = entry.target;

      if (entry.isIntersecting) {
        steps[id].seen = true;
      }
    });
  });

  groups.forEach((group) => {
    observer.observe(group);
  });
});
</script>

<template>
  <div class="flex overflow-y-auto">
    <DialogModal :is-active="showModal" @dismiss="handleModalDismiss">
      <TgCard>
        <FormKit
          id="variable-options-form"
          v-slot="{ state: { valid } }"
          type="form"
          :actions="false"
          @submit="handleSaveVariableOptions"
        >
          <FormKit
            v-model="selectedLabel"
            outer-class="mb-6"
            type="select"
            :options="labelOptions"
            label="Select label"
          />
          <FormKit v-model="isOptional" outer-class="mb-6" type="checkbox" label="Optional" />
          <FormKitSchema v-if="showModal" v-model="defaultValue" :schema="inputSchema" />
          <TgButton is-small :disabled="!valid" @click="submitForm('variable-options-form')">Save</TgButton>
        </FormKit>
      </TgCard>
    </DialogModal>
    <LayoutBox class="flex w-52 flex-col justify-between p-3">
      <div class="mb-3 overflow-auto">
        <ProgressStep
          v-for="(step, key) in steps"
          :key="key"
          :label="key"
          :is-valid="unref(step.valid)"
          :is-dirty="unref(step.dirty)"
          :seen="step.seen"
          :show-stem="key !== firstStep"
          :show-raw-label="!isPattern"
        />
      </div>
      <div class="flex flex-col gap-3">
        <SidebarButtonWithPopover
          v-if="isPattern"
          v-model="documentTitle"
          entity="pattern"
          :is-loading="isSaving"
          :disabled="!isPatternFormValid"
          @save="submitForm('pattern-form')"
        >
          <TgButton class="w-full" is-small :disabled="!isPatternFormValid">Save Pattern</TgButton>
        </SidebarButtonWithPopover>
        <TgButton
          v-else
          is-small
          :is-loading="isSaving"
          color="primary"
          class="w-full"
          @click="submitForm('tender-form')"
        >
          Submit
        </TgButton>
        <router-link :to="{ name: 'patternsList' }">
          <TgButton is-small class="w-full bg-red-200 hover:bg-red-300" @click="handleTrackCancel">Cancel</TgButton>
        </router-link>
      </div>
    </LayoutBox>
    <div class="w-full overflow-y-scroll pl-3">
      <FormKit
        v-if="isPattern"
        id="pattern-form"
        ref="patternForm"
        type="form"
        :plugins="[stepPlugin, pathPlugin, locationPlugin, commodityPlugin, partyPlugin]"
        :actions="false"
        @submit="handleSavePattern"
      >
        <section
          v-for="[groupName, node] in sortedSchema"
          :id="groupName"
          :key="groupName"
          class="waybill-form-group mb-12"
        >
          <FormKit :id="groupName" type="group" :name="groupName">
            <FormKitSchema :schema="node" :data="schemaData" />
          </FormKit>
        </section>
      </FormKit>
      <FormKit
        v-else
        id="tender-form"
        type="form"
        :actions="false"
        :plugins="[stepPlugin]"
        @submit="handleSubmitTender"
      >
        <section v-for="(node, index) in schemaMap" :key="index" class="mb-3">
          <FormKitSchema :schema="node" />
        </section>
      </FormKit>
    </div>
  </div>
</template>
