<script setup lang="ts"></script>

<template>
  <FormKit
    type="checkbox"
    name="lowPriority"
    :classes="{
      wrapper: '!m-0',
      outer: 'grow-0 p-2',
    }"
  >
    <template #label
      ><i class="fa-solid fa-fire-flame-simple pr-1 text-lg text-yellow-500"></i
      ><span class="font-semibold">Low</span></template
    >
  </FormKit>
  <FormKit
    type="checkbox"
    name="mediumPriority"
    :classes="{
      wrapper: '!m-0',
      outer: 'grow-0 p-2',
      label: '!mb-0',
    }"
  >
    <template #label
      ><i class="fa-solid fa-fire-flame-simple pr-1 text-lg text-orange"></i
      ><span class="font-semibold">Medium</span></template
    >
  </FormKit>
  <FormKit
    type="checkbox"
    name="highPriority"
    :classes="{
      wrapper: '!m-0',
      outer: 'grow-0 p-2',
    }"
  >
    <template #label
      ><i class="fa-solid fa-fire-flame-simple pr-1 text-lg text-red-500"></i
      ><span class="font-semibold">High</span></template
    >
  </FormKit>
  <FormKit
    type="toggle"
    name="myTickets"
    label="View My Tickets Only"
    :classes="{
      outer: 'grow-0 p-2 ml-10',
      label: 'font-semibold',
    }"
  />
</template>
