import { makeBaseAsset } from ".";
import { toAlphanumeric } from "@/utils/text";
import { BAPIResponseWaybillAsset, WaybillAsset } from "@/bapi-client/types/assets/waybillAsset";
import { WaybillLocation } from "@/bapi-client/types/common/location";

export function makeWaybillAsset(data: BAPIResponseWaybillAsset): WaybillAsset {
  return {
    ...makeBaseAsset(data),
    shipperName: data.shipper_name,
    consigneeName: data.consignee_name,
    parties: data.parties,
    reportingScac: data.reporting_scac,
    startDate: data.start_date,
    destStartDate: data.dest_start_date,
    endDate: data.end_date,
    lastSightingEventCodeText: data.last_sighting_event_code_text,
    metadata: data.metadata,
    displayEta: data.display_eta,
    equipmentPrivateSystem: data.equipment_private_system,
    destinationDetails: <WaybillLocation>{
      city: data.destination_details.city,
      country: data.destination_details.country,
      displayName: data.destination_details.display_name,
      id: data.destination_details.id,
      latitude: data.destination_details.latitude,
      longitude: data.destination_details.longitude,
      scac: data.destination_details.scac,
      splc: data.destination_details.splc,
      state: data.destination_details.state,
      station: data.destination_details.station,
      timeZone: data.destination_details.time_zone,
    },
    equipmentSealNumber: data.equipment_seal_number,
    shipmentNumber: data.shipment_number,
    grossWeight: data.gross_weight,
    netWeight: data.net_weight,
    quantityValue: data.quantity_value,
    quantityUom: data.quantity_uom,
    cubeCount: data.cube_count,
    dryLoadIndicator: data.dry_load_indicator,
    setPointBolNumber: data.set_point_bol_number,
    requestedLoadedDate: data.requested_loaded_date,
    requestedDeliveryDate: data.requested_delivery_date,
    lastFreeDay: data.last_free_day,
    unloadVerificationDate: data.unload_verification_date,
    reeferOffDate: data.reefer_off_date,
    primarySku: data.primary_sku,
    deliveryNote: data.delivery_note,
    productDescription: data.product_description,
    productNumber: data.product_number,
    routeCode: data.route_code,
    quadLoadNumber: data.quad_load_number,
    trainId: data.train_id,
    get searchTag() {
      return toAlphanumeric(
        `${this.equipmentId} ${this.shipperName}${this.parties}${this.originLocation}${this.destinationLocation}${this.commodityDescription}${this.reportingScac}${this.billOfLadingNumber}${this.sealNumber}${this.status}`,
      );
    },
  };
}
