<script setup lang="ts">
import { ref } from "vue";
import { FilterBox } from "@/components/filters";
import { TextButton, TgButton, OutlineButton } from "@/components/common";
const open = ref(false);

const emit = defineEmits(["apply", "clear"]);

function applyFilters() {
  emit("apply");
  open.value = false;
}
</script>

<template>
  <div class="grid w-full grid-cols-9 gap-1.5 transition-all">
    <FilterBox
      v-if="$slots.primary"
      :class="[!open ? 'hidden' : '']"
      class="col-span-7 rounded-md lg:col-span-3 xl:col-span-2"
    >
      <slot name="primary" />
    </FilterBox>
    <!-- main content section -->
    <div
      :class="{
        'col-span-7 lg:col-span-5 xl:col-span-6': $slots.primary,
        'col-span-7 lg:col-span-8 xl:col-span-8': !$slots.primary,
        hidden: !open,
      }"
    >
      <slot />
    </div>
    <!-- controls and collapsed panel -->
    <FilterBox
      class="row-start-1 gap-1.5 rounded-md bg-white px-0"
      :class="{
        'col-span-2 col-start-8 rounded-md border border-gray-300 !p-0 lg:col-start-9 xl:col-span-1 xl:col-start-9':
          open,
        'col-span-full grid grid-cols-subgrid px-6 py-3': !open,
      }"
    >
      <div class="col-span-8 flex flex-wrap items-center gap-6" :class="{ hidden: open }">
        <slot name="collapsed" />
      </div>
      <div
        class="flex flex-col gap-3 text-xs md:text-sm"
        :class="{
          'col-span-full place-content-center place-items-center px-6 py-4': open,
          'col-span-1 col-start-9 row-start-1 place-items-end': !open,
        }"
      >
        <TextButton
          color="primary"
          class="mb-auto text-xs md:text-base lg:text-lg"
          is-small
          :class="{
            'leading-none': !open,
          }"
          :trailing-icon="open ? 'chevron-up' : 'chevron-down'"
          @click="open = !open"
        >
          Filters
        </TextButton>
        <div class="flex w-full flex-col gap-1.5 self-stretch" :class="{ hidden: !open }">
          <TgButton color="primary" is-small class="text-sm" @click="applyFilters">Apply Filters</TgButton>
          <OutlineButton color="primary" is-small class="text-sm" @click="$emit('clear')">Reset Filters</OutlineButton>
        </div>
      </div>
    </FilterBox>
  </div>
</template>
