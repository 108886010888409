<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

interface Props {
  customerId: string;
  title?: string;
}

const props = defineProps<Props>();

const route = useRoute();
const ticketTitle = computed(() => {
  if (route.path.includes("new")) return "New Ticket";
  return props.title;
});
</script>

<template>
  <div
    class="fixed bottom-0 right-2 z-[999] max-h-screen w-screen overflow-y-auto overscroll-y-contain rounded-lg rounded-bl-none rounded-br-none border-2 border-b-0 border-gray-300 bg-white p-6 shadow-lg lg:max-w-screen-md lg:max-w-screen-md"
  >
    <div class="flex justify-between border-b align-middle">
      <h1
        class="w-11/12 pb-2"
        :class="[route.name === 'newTicket' ? 'text-2xl font-medium' : 'break-words text-base font-semibold']"
      >
        {{ ticketTitle }}
      </h1>
      <router-link :to="{ name: 'customerSupport', params: { customerId } }" data-testid="ticket-panel-dismiss">
        <i class="fa fa-times align-middle" />
      </router-link>
    </div>
    <slot />
  </div>
</template>
