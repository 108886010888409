import type { BAPIAttachmentResponse, HandlerAttachmentResponse } from "../../types/common/attachment";

export function attachmentFactory(attachment: BAPIAttachmentResponse): HandlerAttachmentResponse {
  return {
    id: attachment.id,
    userId: attachment.user_id,
    fileName: attachment.filename,
    fileSize: attachment.filesize,
    extension: attachment.extension,
    altText: attachment.alt_text,
    version: attachment.version,
    status: attachment.status,
    createdTimestamp: attachment.created_ts,
    updatedTimestamp: attachment.updated_ts ?? null,
    mimeType: attachment.mime_type,
    downloadURL: attachment.download_url,
    normalizedURL: attachment.normalized_url,
    thumbnailStatus: attachment.thumbnail_status,
    thumbnailURL: attachment.thumbnail_url,
  };
}
