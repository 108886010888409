<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { useBapi } from "@/bapi-client";
import NoteWriter from "@/components/app/NoteWriter.vue";
import {
  FilePreviewModal,
  ScrollActionList,
  CommentCard,
  DialogModal,
  TgCard,
  TgButton,
  CancelButton,
} from "@/components/common";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import {
  HandlerCustomerSupportTicketResponse,
  HandlerTicketNotesResponse,
  TicketAsset,
  BAPITicketNoteAttachment,
} from "@/bapi-client/types/customer-support";
import { capitalize } from "@/utils/text";
import PopoverTag from "@/components/customerSupport/PopoverTag.vue";
import TicketPanel from "@/components/customerSupport/TicketPanel.vue";
import TicketDetailsAssignee from "./TicketDetailsAssignee.vue";
import { useAttachments } from "@/composables/attachments";
import useNotificationStore from "@/stores/notifications";

const { attachments, thumbnails, loadThumbnails, files, downloadFile, previewFile } = useAttachments();

interface Props {
  customerId: string;
  ticketId: string;
}

const props = defineProps<Props>();

const ticket = ref<HandlerCustomerSupportTicketResponse>();
const ticketNotes = ref<HandlerTicketNotesResponse>();

const imagePreview = ref<{ fileId: string; noteId: string; fileName: string; fileType: string; src: string }>();
const showPreview = ref(false);
const isSaving = ref(false);
const isSaved = ref(false);
const failedToSave = ref(false);
const isCloseTicketModalOpen = ref(false);

interface NoteForm {
  text?: string;
  attachments?: FileList;
}

async function preview(noteId: string, fileId: string) {
  const notifier = useNotificationStore();
  const file = attachments.value[noteId]?.find((att) => att.id === fileId);
  if (!file) return undefined;

  notifier.setLoading();
  const srcURL = await previewFile(noteId, fileId);
  notifier.setLoading();
  if (!srcURL) return undefined;
  imagePreview.value = {
    noteId,
    fileId,
    fileName: file.fileName,
    fileType: file.mimeType,
    src: srcURL,
  };
  showPreview.value = true;
}

async function submitNote(newNote: NoteForm): Promise<void> {
  failedToSave.value = false;
  isSaving.value = true;
  isSaved.value = false;
  if (!newNote.text && !newNote.attachments) {
    const notifier = useNotificationStore();
    notifier.setToast("danger", "Note cannot be empty!");
    isSaving.value = false;
    isSaved.value = false;
    failedToSave.value = true;
    return;
  }
  if (!ticket.value?.customerId || !ticket.value?.id || !ticket.value?.idPrefix) {
    const notifier = useNotificationStore();
    notifier.setToast("danger", "Ticket information is missing!");
    isSaving.value = false;
    isSaved.value = false;
    failedToSave.value = true;
    return;
  }
  let noteAttachments: BAPITicketNoteAttachment[] = [];
  if (newNote.attachments) {
    const attachmentPromises = Array.from(newNote.attachments).map(async (attachment) => {
      const result = await useBapi(
        BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTE_SIGNED_UPLOAD_URL,
        ticket.value!.customerId,
      );
      if (result.success) {
        const uploadResponse = await useBapi(BAPI_COMMANDS.S3_UPLOAD_FILE, attachment, result.data.uploadUrl);
        if (!uploadResponse.success) {
          console.error("Failed to upload file, received ", uploadResponse.error.status);
          const notifier = useNotificationStore();
          notifier.setToast("info", `Could not save attachment: ${attachment.name}`);
          return null;
        }
        return {
          attachment_id: result.data.attachmentId,
          filename: attachment.name,
          mime_type: attachment.type,
          filesize: attachment.size,
          alt_text: attachment.name,
        };
      } else {
        console.error("Failed to get signed upload url for attachment");
        const notifier = useNotificationStore();
        notifier.setToast("info", `Could not save attachment: ${attachment.name}`);
        return null;
      }
    });
    const uploadResults = await Promise.all(attachmentPromises);
    for (const res of uploadResults) {
      if (res !== null) {
        noteAttachments.push(res);
      }
    }
  }

  const ticketNotesResult = await useBapi(BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET_NOTE, {
    customer_id: ticket.value?.customerId,
    ticket_id: ticket.value?.id,
    ticket_prefix: ticket.value?.idPrefix,
    note: newNote.text ?? "",
    attachments: noteAttachments,
  });

  if (ticketNotesResult.success) {
    const notifier = useNotificationStore();
    notifier.setToast("success", "Ticket Comment created!");
    isSaved.value = true;
    if (ticketNotesResult.success) {
      ticketNotes.value = ticketNotesResult.data;
      for (const note of ticketNotes.value) {
        if (!attachments.value[note.id]) {
          attachments.value[note.id] = note.attachments ?? [];
        }
      }
      isSaved.value = true;
    }
  }
  isSaving.value = false;
}

const priorityIconClass = computed(() => {
  if (ticket.value?.priority.toLowerCase() === "high") return "text-red-400";
  if (ticket.value?.priority.toLowerCase() === "medium") return "text-orange";
  return "text-yellow-400";
});

function closePreview() {
  imagePreview.value = undefined;
  showPreview.value = false;
}

const loadedEmpty = computed(() => {
  if (ticket.value?.loadedEmpty === "L") return "Loaded";
  return "Empty";
});

const railroad = computed(() => {
  if (ticket.value?.railroads.length && ticket.value?.railroads.length > 0) return ticket.value?.railroads[0];
  return "- -";
});

const assignee = computed(() => {
  if (ticket.value?.assignees.length && ticket.value?.assignees.length > 0) {
    return ticket.value?.assignees[0].fullName;
  }
  return "- -";
});

const firstTag = computed(() => {
  if (ticket.value?.tags.length && ticket.value?.tags.length > 0) {
    return ticket.value?.tags[0];
  }
  return "- -";
});

const moreTags = computed(() => {
  if (ticket.value?.tags.length && ticket.value?.tags.length > 1) {
    return ticket.value?.tags.slice(1);
  }
  return undefined;
});

const displayAssets = computed(() => {
  let assetArray: TicketAsset[] = [];
  if (ticket.value?.assetIds.length && ticket.value?.assetIds.length > 0) {
    ticket.value.assetIds.forEach((id) => {
      let foundAsset = ticket.value?.ticketAssets.find((obj) => {
        return obj.assetId === id;
      });
      if (foundAsset) assetArray.push(foundAsset);
      if (!foundAsset) {
        assetArray.push({
          assetId: id,
          waybillId: "",
          waybillDate: "",
          waybillNumber: "",
          reportingGroups: [],
        });
      }
    });
  }
  return assetArray;
});

async function getTicket() {
  const ticketResult = await useBapi(BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET, props.customerId, props.ticketId);
  if (ticketResult.success) {
    ticket.value = ticketResult.data;
  }
}

async function getTicketNotes() {
  const ticketNotesResult = await useBapi(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKET_NOTES,
    props.customerId,
    props.ticketId,
  );
  if (ticketNotesResult.success) {
    ticketNotes.value = ticketNotesResult.data;
    for (const note of ticketNotes.value) {
      if (!attachments.value[note.id]) {
        attachments.value[note.id] = note.attachments ?? [];
      }
    }
  }
}

async function closeTicket() {
  const result = await useBapi(BAPI_COMMANDS.CUSTOMER_SUPPORT_UPDATE_TICKET, {
    customer_id: props.customerId,
    ticket_id: props.ticketId,
    state: "Closed",
  });
  const notifier = useNotificationStore();
  if (!result.success) {
    notifier.setToast("danger", `Unable to close ticket.`);
  } else {
    notifier.setToast("success", `Ticket ${props.ticketId} closed.`);
    getTicket();
  }
}

onMounted(() => {
  getTicket();
  getTicketNotes();
});

watch(
  () => props.ticketId,
  () => {
    getTicket();
    getTicketNotes();
  },
);
</script>

<template>
  <DialogModal :is-active="isCloseTicketModalOpen" @dismiss="isCloseTicketModalOpen = false">
    <TgCard>
      <template #title> Close {{ ticket?.qualifiedId }} </template>
      <p class="mb-6 w-96">
        Are you sure you want to close the ticket? You will not be able to edit the ticket fields or change the assignee
        after the ticket is closed.
      </p>
      <div class="flex flex-row-reverse items-center gap-2 border-t pt-2">
        <TgButton data-test="admin-refresh-submit" color="danger" @click="closeTicket"> Close </TgButton>
        <CancelButton
          color="primary"
          data-test="admin-refresh-cancel"
          :is-small="false"
          @click="isCloseTicketModalOpen = false"
        >
          Cancel
        </CancelButton>
      </div>
    </TgCard>
  </DialogModal>
  <TicketPanel :customer-id="customerId" :title="ticket?.name">
    <div class="min-w-[600px]">
      <div class="flex gap-3 border-b py-4">
        <span class="!mb-0 w-[12.5%] self-center text-sm font-semibold !text-gray-500">Status</span>
        {{ ticket?.state && capitalize(ticket.state) }}
      </div>
      <div class="grid grid-cols-2 grid-rows-3 gap-3 border-b py-4">
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">Priority</span>
          <span>
            <i class="fa-solid fa-fire-flame-simple mr-1 text-lg" :class="priorityIconClass" />{{
              ticket?.priority && capitalize(ticket.priority)
            }}
          </span>
        </div>
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">Creator</span>
          {{ ticket?.createdBy.fullName }}
        </div>
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">L/E</span>
          {{ loadedEmpty }}
        </div>
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">Ticket #</span>
          {{ ticket?.id }}
        </div>
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">Railroad</span>
          {{ railroad }}
        </div>
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">Date</span>
          {{ ticket?.createdTs }}
        </div>
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">Tags</span>
          {{ firstTag }}
          <PopoverTag v-if="moreTags" name="tags" :label="`+ ${moreTags.length}`">
            <ul class="divide-y">
              <template v-for="tag in moreTags" :key="tag">
                <li class="px-3 py-1.5 text-sm font-semibold">{{ tag }}</li>
              </template>
            </ul>
          </PopoverTag>
        </div>
        <div class="flex items-center gap-3">
          <span class="!mb-0 w-1/4 text-sm font-semibold !text-gray-500">Assignee</span>
          {{ assignee }}
        </div>
      </div>
      <div v-if="displayAssets.length > 0" class="flex flex-col border-b py-4">
        <div v-for="asset in displayAssets" :key="asset.assetId" class="justify-left mb-4 flex w-full gap-3">
          <div class="flex items-center gap-3">
            <span class="!mb-0 text-sm font-semibold !text-gray-500">Asset ID</span>
            <router-link
              v-if="asset.waybillId"
              :to="{ name: 'assetDetails', params: { companyId: customerId, waybillId: asset.waybillId } }"
              class="font-semibold text-blue hover:text-blue-700 hover:underline"
            >
              {{ asset.assetId }}
            </router-link>
            <span v-else>{{ asset.assetId }}</span>
          </div>
          <div class="flex items-center gap-3">
            <span class="!mb-0 text-sm font-semibold !text-gray-500">Shipment ID</span>
            <span v-if="asset.waybillId"> {{ asset.waybillDate }} #{{ asset.waybillNumber }} </span>
            <span v-else> - - </span>
          </div>
          <div class="flex items-center gap-3">
            <span class="!mb-0 text-sm font-semibold !text-gray-500">Reporting Group</span>
            <span v-if="Object.values(asset.reportingGroups).length > 0">
              {{ Object.values(asset.reportingGroups[0])[0] }}
              <PopoverTag
                v-if="Object.keys(asset.reportingGroups).length > 1"
                name="reportingGroups"
                :label="`+ ${Object.keys(asset.reportingGroups).length - 1}`"
              >
                <ul class="divide-y">
                  <template v-for="group in Object.values(asset.reportingGroups.slice(1))" :key="group">
                    <li class="px-3 py-1.5 text-sm font-semibold">{{ group }}</li>
                  </template>
                </ul>
              </PopoverTag>
            </span>
            <span v-else> - - </span>
          </div>
        </div>
      </div>
      <div v-if="ticket?.description" class="flex gap-3 border-b py-4">
        {{ ticket?.description }}
      </div>
      <div
        v-if="ticket && ticket.state !== 'Closed'"
        class="flex w-full items-center justify-between gap-3 border-b py-3"
      >
        <router-link
          class="h-8 rounded-md border border-blue-500 bg-transparent px-3 pt-[3px] font-semibold text-blue-500 shadow-sm hover:bg-blue-500/15 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          :to="{ name: 'editTicket', params: { ticketId } }"
          >Edit ticket</router-link
        >
        <TicketDetailsAssignee
          :ticket="ticket"
          :class="{
            'ml-auto': !ticket.assignees.length,
            'mr-auto': ticket.assignees.length,
          }"
          @assigned="getTicket"
        />
        <button
          class="h-8 rounded-md bg-blue-500 px-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          @click="isCloseTicketModalOpen = true"
        >
          Close ticket
        </button>
      </div>
      <div v-if="ticketNotes && ticketNotes.length > 0" class="flex gap-3 border-b py-4">
        <FilePreviewModal
          v-if="imagePreview"
          :is-active="showPreview"
          :file-type="imagePreview?.fileType"
          :file-name="imagePreview?.fileName"
          :url="imagePreview?.src"
          @download="downloadFile(imagePreview?.noteId, imagePreview.fileId)"
          @dismiss="closePreview"
        />
        <ScrollActionList class="pr-2" :show-divider="false" :scroll-down="true" @visible="loadThumbnails">
          <CommentCard
            v-for="note in ticketNotes ?? []"
            :id="note.id"
            :key="note.id"
            :note="note.note"
            :note-id="note.id"
            :user-id="note.user.userId"
            :user-name="note.user.fullName"
            :user-initials="note.user.firstName[0] + note.user.lastName[0]"
            :thumbnails="thumbnails[note.id]"
            :is-clickable="false"
            :files="files[note.id]"
            :file-count="attachments[note.id]?.length || 0"
            :timestamp="note.createdTs"
            :profile-picture="note.user.profilePicture"
            :show-attachments="true"
            @download-file="downloadFile(note.id, $event)"
            @preview-file="preview(note.id, $event)"
          />
        </ScrollActionList>
      </div>
      <div class="flex gap-3 py-4">
        <NoteWriter
          :ticket-id="ticket?.qualifiedId ?? ''"
          :is-saving="isSaving"
          :is-saved="isSaved"
          @submit-note="submitNote"
        />
      </div>
    </div>
  </TicketPanel>
</template>
