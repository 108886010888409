<script setup lang="ts">
import { computed } from "vue";
import { CustomerSupportTicket } from "@/bapi-client/types/customer-support";
import AssetFieldsItem from "@/components/customerSupport/AssetFieldsItem.vue";

interface Props {
  customerId: string;
  labelClasses: string;
  ticket?: CustomerSupportTicket;
}

const props = defineProps<Props>();
const equipment = computed(() => {
  if (!props.ticket) return undefined;
  const out = [];
  for (const asset of props.ticket.assetIds) {
    const found = props.ticket.ticketAssets?.find((ass) => ass.assetId === asset);
    if (found) {
      out.push({ equipment_id: asset, waybill_id: found.waybillId, reporting_groups: found.reportingGroups });
    } else {
      out.push({ equipment_id: asset, waybill_id: undefined });
    }
  }
  return out;
});
</script>

<template>
  <FormKit
    name="equipment"
    type="repeater"
    :up-control="false"
    :down-control="false"
    :classes="{
      item: 'border-0 group',
      content: '!p-0 !flex-row gap-3 w-full',
      controls: 'border-0 !py-1',
      insert: 'group-[:not(:first-of-type)]:hidden',
      remove: 'group-first:hidden',
      outer: 'grow',
    }"
    :add-button="false"
    add-icon="faPlusSquare"
    remove-icon="faMinusSquare"
    :insert-control="true"
    :remove-control="true"
    :value="equipment"
  >
    <AssetFieldsItem :customer-id="customerId" :label-classes="labelClasses" />
    <template #addIcon>
      <i class="fa-solid fa-plus-square text-xl text-blue-500" />
    </template>
    <template #removeIcon>
      <i class="fa-solid fa-minus-square text-xl text-blue-500" />
    </template>
  </FormKit>
</template>
