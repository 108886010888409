<script setup lang="ts">
import { watch, ref, onMounted, provide } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { onBeforeRouteUpdate } from "vue-router";
import { getNode } from "@formkit/core";
import { useFormKitContextById } from "@formkit/vue";
import CustomerSupportHeader from "@/components/customerSupport/CustomerSupportHeader.vue";
import FilterPanel from "@/components/filters/FilterPanel.vue";
import FilterChip from "@/components/filters/FilterChip.vue";
import LayoutBox from "@/components/layout/LayoutBox.vue";
import TicketTable from "@/components/customerSupport/TicketTable.vue";
import PriorityFilter from "@/components/customerSupport/PriorityFilter.vue";
import StatusFilter from "@/components/customerSupport/StatusFilter.vue";
import useNotificationStore from "@/stores/notifications";
import { useUserStore } from "@/stores/user";
import { useBapi } from "@/bapi-client";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { HandlerCustomerSupportTicketResponse } from "@/bapi-client/types/customer-support";

const props = defineProps<{ customerId: string }>();
const userStore = useUserStore();

const pageNumber = useRouteQuery("page_number", "1", { transform: Number });
const pageSize = useRouteQuery("page_size", "25", { transform: Number });
const sortColumn = useRouteQuery("sort_by", "created_ts");
const sortDirection = useRouteQuery("sort_by_direction", "ASC");

const debouncedFetch = useDebounceFn(() => getTickets(), 600);

const tickets = ref<HandlerCustomerSupportTicketResponse[]>([]);
const reportingGroups = ref<Record<string, string>>({});
const totalTickets = ref<number>(0);

interface ChipValues {
  chipValue: string | number;
  disabled: boolean;
}

const filterDefaults = {
  unassigned: true,
  inProgress: true,
  closed: false,
  lowPriority: true,
  mediumPriority: true,
  highPriority: true,
  myTickets: false,
};

interface FilterForm {
  lowPriority: boolean;
  mediumPriority: boolean;
  highPriority: boolean;
  myTickets: boolean;
  ticketState: string[];
}
const statusOpts: Map<string, string> = new Map([
  ["unassigned", "Unassigned"],
  ["inProgress", "In Progress"],
  ["closed", "Closed"],
]);
const priorityOpts: Map<string, string> = new Map([
  ["lowPriority", "Low"],
  ["mediumPriority", "Medium"],
  ["highPriority", "High"],
]);
const statusChip = ref<ChipValues>();
const priorityChip = ref<ChipValues>();
const myTicketsChip = ref<ChipValues | undefined>();

const formContext = useFormKitContextById("csFilters");

function resetFilters() {
  formContext.value?.node.reset();
  getTickets();
}

function processFilterGroup(opts: Map<string, string>, group: string[]) {
  let filterString = "";
  let filterCount = 0;
  opts.forEach((value) => {
    if (group.includes(value)) {
      filterString += value + ",";
      filterCount++;
    }
  });
  return { filterString, filterCount };
}

function setFilterChipValue(filterCount: number, allCount: number, filterString: string) {
  if (filterCount === allCount) return "All";
  if (filterCount === 1) return filterString.replace(/,/g, "");
  return filterCount;
}

function processFilters(form: FilterForm) {
  const params: Record<string, string> = {
    priority: "",
    assignees: "",
    state: "",
  };
  const statuses = processFilterGroup(statusOpts, form.ticketState);
  statusChip.value = {
    chipValue: setFilterChipValue(statuses.filterCount, 3, statuses.filterString),
    disabled:
      form.ticketState.includes("Unassigned") &&
      form.ticketState.includes("In Progress") &&
      !form.ticketState.includes("Closed"),
  };
  params.state = statuses.filterString;
  const priorities: string[] = [];
  priorityOpts.forEach((value, key) => {
    if (form[key as keyof FilterForm]) priorities.push(value);
  });
  let prioritiesFilterString: string = "";
  if (priorities.length > 0) priorities.forEach((priority) => (prioritiesFilterString += priority + ","));
  priorityChip.value = {
    chipValue: setFilterChipValue(priorities.length, 3, prioritiesFilterString),
    disabled: priorities.length === 3,
  };
  params.priority = prioritiesFilterString.toLowerCase();
  if (form.myTickets && userStore.user?.userId) {
    params.assignees = userStore.user.userId;
    myTicketsChip.value = { chipValue: "Selected", disabled: false };
  }
  if (!form.myTickets) myTicketsChip.value = undefined;
  return params;
}

async function getTickets() {
  const notifier = useNotificationStore();
  notifier.setLoading();
  const data = getNode("csFilters");
  const formData: FilterForm = data?.value as FilterForm;
  const params = processFilters(formData);

  const response = await useBapi(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_GET_TICKETS,
    props.customerId,
    params,
    sortColumn.value,
    sortDirection.value,
    pageNumber.value,
    pageSize.value,
  );

  if (!response.success) {
    notifier.setLoading();
    notifier.setToast("danger", "Failed to load tickets");
    console.error("Failed to fetch tickets", response.error);
    return;
  }
  notifier.setLoading();
  tickets.value = response.data.tickets;
  reportingGroups.value = response.data.reportingGroups;
  totalTickets.value = response.data.totalRecordCount || 0;
}

provide("ticket_list", tickets);
provide("get_tickets", getTickets);

watch([pageNumber, pageSize, sortColumn, sortDirection], () => {
  debouncedFetch();
});

onMounted(() => {
  getTickets();
});

onBeforeRouteUpdate((next, prev) => {
  if (next.name === "customerSupport" && prev.name !== "customerSupport") {
    getTickets();
  }
});
</script>

<template>
  <section class="p-6 pb-0">
    <CustomerSupportHeader :customer-id="customerId" />
    <FilterPanel class="my-4" @apply="getTickets" @clear="resetFilters">
      <template #collapsed>
        <FilterChip label="Status" :disabled="statusChip?.disabled">
          {{ statusChip?.chipValue }}
        </FilterChip>
        <FilterChip label="Priority" :disabled="priorityChip?.disabled">
          {{ priorityChip?.chipValue }}
        </FilterChip>
        <FilterChip v-if="myTicketsChip" label="My Tickets" :disabled="myTicketsChip?.disabled">
          {{ myTicketsChip?.chipValue }}
        </FilterChip>
      </template>
      <FormKit id="csFilters" type="form" :actions="false" :value="filterDefaults">
        <LayoutBox class="mb-3 flex items-center justify-start p-3">
          <StatusFilter />
        </LayoutBox>
        <LayoutBox class="flex items-center justify-start p-3">
          <PriorityFilter />
        </LayoutBox>
      </FormKit>
    </FilterPanel>
    <TicketTable
      :customer-id="customerId"
      :tickets="tickets"
      :reporting-groups="reportingGroups"
      :total-record-count="totalTickets"
      class="my-6 rounded-md border"
      @refresh="getTickets"
    />
    <router-view />
  </section>
</template>
