<script setup lang="ts">
import type { HandlerCustomerSupportTicketResponse } from "@/bapi-client/types/customer-support";
import { ProfilePicture } from "../common";
import PopoverTag from "./PopoverTag.vue";
import TicketTableAssignee from "./TicketTableAssignee.vue";
import { useUserStore } from "@/stores/user";
import useNotificationStore from "@/stores/notifications";

type Props = {
  ticket: HandlerCustomerSupportTicketResponse;
  customerId: string;
  reportingGroups?: string[];
};

defineProps<Props>();
const userStore = useUserStore();
const notifier = useNotificationStore();
</script>

<template>
  <tr class="whitespace-nowrap bg-white text-base font-medium leading-none">
    <td class="border p-3 text-center text-xl">
      <span
        :class="{
          'text-red-500': ticket.priority === 'high',
          'text-orange': ticket.priority === 'medium',
          'text-yellow-500': ticket.priority === 'low',
        }"
      >
        <i class="fa-solid fa-fire-flame-simple"></i>
      </span>
    </td>
    <td class="border py-3 pl-6 pr-3">
      <router-link
        :to="{ name: 'ticket', params: { customerId, ticketId: ticket.id } }"
        class="text-blue hover:text-blue-700 hover:underline"
      >
        {{ ticket.qualifiedId }}
      </router-link>
    </td>
    <td class="max-w-96 overflow-hidden overflow-ellipsis whitespace-nowrap border py-3 pl-6 pr-3" :title="ticket.name">
      {{ ticket.name }}
    </td>
    <td class="border py-3 pl-6 pr-3 text-left">
      <div class="flex items-center gap-1.5">
        <template v-if="reportingGroups && reportingGroups.length > 0">
          <span>{{ reportingGroups[0] }}</span>
        </template>
        <template v-if="reportingGroups && reportingGroups.length > 1">
          <PopoverTag :name="ticket.name + '-reporting-groups'" :label="`+ ${reportingGroups.length - 1}`">
            <ul class="divide-y">
              <template v-for="(group, idx) in reportingGroups" :key="group">
                <li v-if="idx > 0" class="p-3">{{ group }}</li>
              </template>
            </ul>
          </PopoverTag>
        </template>
      </div>
    </td>
    <td class="border py-3 pl-6 pr-3 text-left">
      <template v-if="ticket.ticketAssets.length > 1">
        <div class="flex items-center gap-1.5">
          <span>{{ ticket.ticketAssets[0].assetId }}</span>
          <PopoverTag :name="ticket.name + '-waybill-equipment'" :label="`+ ${ticket.ticketAssets.length - 1}`">
            <ul class="divide-y">
              <template v-for="(asset, idx) in ticket.ticketAssets" :key="asset">
                <li v-if="idx > 0" class="p-3">{{ asset.assetId }}</li>
              </template>
            </ul>
          </PopoverTag>
        </div>
      </template>
      <template v-if="ticket.ticketAssets.length === 1">
        {{ ticket.ticketAssets[0].assetId }}
      </template>
      <template v-if="ticket.ticketAssets.length === 0 && ticket.assetIds.length > 1">
        <div class="flex items-center gap-1.5">
          <span>{{ ticket.assetIds[0] }}</span>
          <PopoverTag :name="ticket.name + '-equipment'" :label="`+ ${ticket.assetIds.length - 1}`">
            <ul class="divide-y">
              <template v-for="(asset, idx) in ticket.assetIds" :key="asset">
                <li v-if="idx > 0" class="p-3">{{ asset }}</li>
              </template>
            </ul>
          </PopoverTag>
        </div>
      </template>
      <template v-if="ticket.ticketAssets.length === 0 && ticket.assetIds.length === 1">
        {{ ticket.assetIds[0] }}
      </template>
    </td>
    <td class="border py-3 pl-6 pr-3">
      {{ ticket.railroads[0] }}
    </td>
    <td class="w-6 border pl-6">{{ ticket.loadedEmpty }}</td>
    <td class="w-60 border py-3 pl-6 pr-3">
      <template v-if="ticket.tags.length > 1">
        <div class="flex items-center gap-1.5">
          <span>{{ ticket.tags[0] }}</span>
          <PopoverTag :name="ticket.name + '-tags'" :label="`+ ${ticket.tags.length - 1}`">
            <ul class="divide-y">
              <template v-for="(tag, idx) in ticket.tags" :key="tag">
                <li v-if="idx > 0" class="p-3">{{ tag }}</li>
              </template>
            </ul>
          </PopoverTag>
        </div>
      </template>
      <template v-if="ticket.tags.length === 1">
        {{ ticket.tags[0] }}
      </template>
    </td>
    <td class="border py-3 pl-6 pr-3">{{ $date("MM/DD/YY", ticket.createdTs) }}</td>
    <td class="border py-3 pl-6 pr-3">
      {{ $date("MM/DD/YY", ticket.createdTs) }}
    </td>
    <td class="border py-3 pl-6 pr-3 capitalize">{{ ticket.state }}</td>
    <td class="border py-3 pl-6 pr-3">
      <template v-if="ticket.assignees.length">
        <div class="flex items-center gap-1.5">
          <ProfilePicture
            :url="ticket.assignees[0]?.profilePicture?.thumbnailUrl"
            :initials="`${ticket.assignees[0].firstName.charAt(0)}${ticket.assignees[0].lastName.charAt(0)}`"
            size="2"
          />
          <p>{{ ticket.assignees[0].firstName + " " + ticket.assignees[0].lastName }}</p>
        </div>
      </template>
      <TicketTableAssignee
        v-if="!ticket.assignees?.length && ticket.state !== 'Closed'"
        :ticket="ticket"
        :current-user="userStore.user?.userId ?? ''"
        @success="notifier.setToast('success', `Ticket ${$event} assigned!`)"
        @error="notifier.setToast('danger', 'Unable to assign ticket.')"
      />
    </td>
  </tr>
</template>
